import {
  Row,
  Col,
  Card,
  Descriptions,
  Typography,
  Form,
  Input,
  Button,
  Switch,
  message,
} from "antd";
import React, { useState, useEffect, useContext } from "react";

const National = ({ client }) => {
  const picContainerStyle = {
    backgroundImage: client && client.idfront ? `url(${client.idfront})` : "",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    width: "50%",
    height: "400px",
  };

  const backContainerStyle = {
    backgroundImage:
      client && client.national_id_pic ? `url(${client.national_id_pic})` : "",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    width: "50%",
    height: "400px",
  };

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col span={12} md={12} className="mb-24">
          <Card
            className="header-solid h-full"
            bordered={false}
            title={<h6 className="font-semibold m-0">ID Front</h6>}
            bodyStyle={{ paddingTop: "0" }}
          >
            <Row gutter={[24, 24]}>
              <div style={picContainerStyle}></div>
            </Row>
          </Card>
        </Col>
        <Col span={12} md={12} className="mb-24">
          <Card
            className="header-solid h-full"
            bordered={false}
            title={<h6 className="font-semibold m-0">ID Back</h6>}
            bodyStyle={{ paddingTop: "0" }}
          >
            <Row gutter={[24, 24]}>
              <div style={backContainerStyle}></div>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default National;

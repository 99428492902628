import { Row, Col, Card, Typography, Form, Input, Button } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Loader from "../components/loader";
import { useLocation, useHistory } from "react-router-dom";

const Sms = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const location = useLocation();
  const history = useHistory();

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // Make a POST request using Axios
      await axios
        .post("https://cgrate.pickmesms.com/gpnsms", {
          message: values.message,
          phone: values.phone,
          sender_id: process.env.REACT_APP_SENDER_ID,
        })
        .then(() => {
          form.resetFields();
          setLoading(false);
          history.push("/dashboard");
        });
    } catch (error) {
      setLoading(false);
      history.push("/dashboard");
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Single SMS</Title>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                className="username"
                label="Phone Number"
                name="phone"
                initialValue={
                  location.state && location.state.phone
                    ? location.state.phone
                    : ""
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                  type="number"
                  placeholder="Phone starting with 260"
                  onChange={handlePhoneChange}
                />
              </Form.Item>
              <Form.Item
                className="username"
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Loader loading={loading} />
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Bulk SMS</Title>
            <Form name="myForm" onFinish={onFinish}>
              <Form.Item
                className="username"
                label="Message all Debtors"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Sms;

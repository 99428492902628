import { useContext, useState, PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  Col,
  Row,
  Avatar,
  Button,
  Typography,
  Table,
  Input,
  Empty,
  Collapse,
  Badge,
  Space,
} from "antd";
import {
  CheckOutlined,
  ArrowDownOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { userContext } from "../context/UserContext";
import React from "react";
import { useHistory } from "react-router-dom";

const Home = () => {
  const { Title } = Typography;

  //const { cgrate } = useContext(userContext);
  const { branch } = useContext(userContext);

  const history = useHistory();
  const handleViewRecord = (loan) => {
    // Navigate to another component and pass the record data
    history.push("/loandetails", { loan });
  };

  const dollor = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
        fill="#fff"
      ></path>
      <path
        d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
        fill="#fff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const totalPendingBalance = branch.loans.reduce((accumulator, loan) => {
    if (loan.status === "pending" && loan.balance && !isNaN(loan.balance)) {
      return accumulator + parseFloat(loan.balance);
    }
    return accumulator;
  }, 0);

  const totalApplications = branch.loans.reduce((accumulator, loan) => {
    if (loan.status === "pending" && loan.amount && !isNaN(loan.amount)) {
      return accumulator + parseFloat(loan.amount);
    }
    return accumulator;
  }, 0);

  const totalActiveLoans = branch.loans.reduce((accumulator, loan) => {
    if (loan.status === "approved" && loan.balance && !isNaN(loan.balance)) {
      return accumulator + parseFloat(loan.balance);
    }
    return accumulator;
  }, 0);

  const count = [
    {
      today: "Active Loans",
      title: branch.loans.filter((loan) => loan.status === "approved").length,
      persent: "",
      icon: dollor,
      bnb: "bnb2",
    },
    {
      today: "Active Loans Total",
      title: "K" + totalActiveLoans.toLocaleString(),
      persent: "",
      icon: dollor,
      bnb: "bnb2",
    },
    {
      today: "Loan Applications",
      title: branch.loans.filter((loan) => loan.status === "pending").length,
      persent: "",
      icon: <ArrowDownOutlined />,
      bnb: "redtext",
    },
    {
      today: "Applications Total",
      title: "K" + totalApplications.toLocaleString(),
      persent: "",
      icon: <CheckOutlined />,
      bnb: "bnb2",
    },
  ];

  const addDays = (numberOfDaysToAdd, date) => {
    const [day, month, year] = date.split("-").map(Number);

    // Create a new Date object
    const originalDate = new Date(year, month - 1, day); // Months are 0-based

    originalDate.setDate(originalDate.getDate() + numberOfDaysToAdd);

    // Convert the new date to the desired format (dd-mm-yyyy)
    const newDay = String(originalDate.getDate()).padStart(2, "0");
    const newMonth = String(originalDate.getMonth() + 1).padStart(2, "0");
    const newYear = originalDate.getFullYear();
    const formattedNewDate = `${newDay}-${newMonth}-${newYear}`;

    return formattedNewDate;
  };

  const today = new Date();

  // Format today's date as "DD-MM-YYYY"
  const todayDate = `${today.getDate().toString().padStart(2, "0")}-${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getFullYear()}`;

  const handlePhoneNumberClick = (phone) => {
    history.push("/sms", { phone });
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (name, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={record.debtor_id.photo}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record.debtor_id.first_name + " " + record.debtor_id.last_name}
            </Title>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "AMOUNT REQUESTED",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <>
          <div className="author-info">
            <Title level={5}>K{parseFloat(amount).toLocaleString()}</Title>
          </div>
        </>
      ),
    },

    {
      title: "APPLICATION DATE",
      dataIndex: "application_date",
      key: "application_date",
      render: (application_date, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{application_date}</Title>
          </div>
        </>
      ),
    },

    {
      title: "LOAN TYPE",
      key: "tenor",
      dataIndex: "tenor",
      render: (tenor, record) => (
        <div className="author-info">
          <Title level={5}>{record.loantype.loan_name}</Title>
        </div>
      ),
    },
    {
      title: (
        <Space>
          <span>SEND SMS</span>
          <MessageOutlined style={{ color: "green" }} />
        </Space>
      ),
      key: "phone",
      dataIndex: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <Button
              type="link"
              onClick={() => handlePhoneNumberClick(record.debtor_id.phone)}
              level={5}
            >
              {record.debtor_id.phone}
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "view",
      render: (text, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          Manage
        </Button>
      ),
    },
  ];

  const columnsDue = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (name, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={record.debtor_id.photo}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record.debtor_id.first_name + " " + record.debtor_id.last_name}
            </Title>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "AMOUNT REQUESTED",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <>
          <div className="author-info">
            <Title level={5}>K{parseFloat(amount).toLocaleString()}</Title>
          </div>
        </>
      ),
    },
    {
      title: "AMOUNT DUE",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => (
        <>
          <div className="author-info">
            <Title level={5} style={{ color: "red" }}>
              K{parseFloat(balance).toLocaleString()}
            </Title>
          </div>
        </>
      ),
    },

    {
      title: "PAYMENT DUE ON",
      dataIndex: "next_date",
      key: "next_date",
      render: (loan_date, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.next_date}</Title>
          </div>
        </>
      ),
    },
    {
      title: (
        <Space>
          <span>SEND SMS</span>
          <MessageOutlined style={{ color: "green" }} />
        </Space>
      ),
      key: "phone",
      dataIndex: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <Button
              type="link"
              onClick={() => handlePhoneNumberClick(record.debtor_id.phone)}
              level={5}
            >
              {record.debtor_id.phone}
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "view",
      render: (text, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          Manage
        </Button>
      ),
    },
  ];

  const columnsOverDue = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (name, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={record.debtor_id.photo}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record.debtor_id.first_name + " " + record.debtor_id.last_name}
            </Title>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "AMOUNT REQUESTED",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <>
          <div className="author-info">
            <Title level={5}>K{parseFloat(amount).toLocaleString()}</Title>
          </div>
        </>
      ),
    },
    {
      title: "AMOUNT DUE",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => (
        <>
          <div className="author-info">
            <Title level={5} style={{ color: "red" }}>
              K{parseFloat(balance).toLocaleString()}
            </Title>
          </div>
        </>
      ),
    },

    {
      title: "PAYMENT DUE ON",
      dataIndex: "next_date",
      key: "next_date",
      render: (loan_date, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.next_date}</Title>
          </div>
        </>
      ),
    },
    {
      title: (
        <Space>
          <span>SEND SMS</span>
          <MessageOutlined style={{ color: "green" }} />
        </Space>
      ),
      key: "phone",
      dataIndex: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <Button
              type="link"
              onClick={() => handlePhoneNumberClick(record.debtor_id.phone)}
              level={5}
            >
              {record.debtor_id.phone}
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "view",
      render: (text, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          Manage
        </Button>
      ),
    },
  ];

  const loansDueTodayCount = branch.loans.filter(
    (loan) =>
      loan.status === "approved" &&
      addDays(loan.loantype.duration, loan.loan_date) === todayDate
  ).length;

  const loansOverdueCount = branch.loans.filter(
    (loan) => loan.status === "approved" && loan.next_date < todayDate
  ).length;

  const convertDate = (date) => {
    const [day, month, year] = date.split("-").map(Number);
    const newDate = new Date(year, month - 1, day);

    newDate.setDate(newDate.getDate());

    // Convert the new date to the desired format (dd-mm-yyyy)
    const newDay = String(newDate.getDate()).padStart(2, "0");
    const newMonth = String(newDate.getMonth() + 1).padStart(2, "0");
    const newYear = newDate.getFullYear();
    const formattedNewDate = `${newDay}-${newMonth}-${newYear}`;

    return formattedNewDate;
  };
  const approvedLoans = branch.loans.filter(
    (loan) => loan.status === "approved"
  );

  const transformedData = approvedLoans
    .map((loan) => ({
      name: convertDate(loan.loan_date),
      amount: loan.amount,
      balance: loan.balance,
    }))
    .slice(0, 10);

  return (
    <>
      <h1>
        <b>{branch.branchname}</b>
      </h1>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={true} className="branchbox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={4}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Collapse bordered={false} defaultActiveKey={["1"]}>
                <Collapse.Panel
                  key="1"
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>Loan Applications</span>
                      <Badge
                        count={
                          branch.loans.filter(
                            (loan) => loan.status === "pending"
                          ).length
                        }
                        style={{
                          backgroundColor: "#52c41a",
                          marginLeft: "8px",
                        }}
                      />
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={branch.loans.filter(
                        (loan) => loan.status === "pending"
                      )}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 5,
                      }}
                    />
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Collapse bordered={false} defaultActiveKey={[]}>
                <Collapse.Panel
                  key="1"
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>Loans Due Today</span>
                      <Badge
                        count={loansDueTodayCount}
                        style={{
                          backgroundColor: "orange",
                          marginLeft: "8px",
                        }}
                      />
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={columnsDue}
                      dataSource={branch.loans.filter(
                        (loan) =>
                          loan.status === "approved" &&
                          addDays(loan.loantype.duration, loan.loan_date) ===
                            todayDate
                      )}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 5,
                      }}
                      locale={{
                        emptyText: <Empty description="No Loans Due Today" />,
                      }}
                    />
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Collapse bordered={false} defaultActiveKey={[]}>
                <Collapse.Panel
                  key="1"
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>Overdue Payments</span>
                      <Badge
                        count={loansOverdueCount}
                        style={{
                          backgroundColor: "red",
                          marginLeft: "8px",
                        }}
                      />
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={columnsOverDue}
                      dataSource={branch.loans.filter(
                        (loan) =>
                          loan.status === "approved" &&
                          loan.next_date < todayDate
                      )}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 5,
                      }}
                      locale={{
                        emptyText: <Empty description="No Overdue Payments" />,
                      }}
                    />
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </div>

        <Row gutter={[24, 0]}>
          <Col span={24} md={24} className="mb-24">
            <Card
              className="header-solid h-full"
              bordered={false}
              title={<h6 className="font-semibold m-0">Loan History</h6>}
              bodyStyle={{ paddingTop: "0" }}
            >
              <Row gutter={[24, 24]}>
                <div>
                  <LineChart
                    width={1000}
                    height={500}
                    data={transformedData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="amount"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="balance" stroke="#82ca9d" />
                  </LineChart>
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Home;

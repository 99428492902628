import React from "react";
import { ThreeDots } from "react-loader-spinner";
const Loader = ({ loading }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ThreeDots
        height="40"
        width="40"
        radius="4"
        color="#1890FF"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={loading}
      />
    </div>
  );
};

export default Loader;

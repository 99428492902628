import React, { useContext } from "react";
import { userContext } from "../context/UserContext";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Row, Typography, Layout } from "antd";
import { HomeFilled } from "@ant-design/icons";
import axios from "axios";
import Loader from "../components/loader";

const Branches = () => {
  const [items, setItems] = useState([]);
  const { Title } = Typography;
  const { Header, Content } = Layout;
  const [loading, setLoading] = useState(true);

  const { setBranch } = useContext(userContext);

  const history = useHistory();

  const handleCardClick = (branch) => {
    setBranch(branch);

    history.push("/dashboard");
  };

  const getItems = async () => {
    try {
      const response = await axios.get(
        "https://gpnserver.pickmesms.com/items/branches?fields=*.*.*.*",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      setItems(response.data.data);
      setLoading(false);
    } catch (error) {
      history.push("/sign-in");
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <h3>{process.env.REACT_APP_NAME}</h3>
          </div>
        </Header>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Content className="signin">
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {items
                .filter((item) => item.id === 9)
                .map((item, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="mb-24"
                  >
                    <Card
                      bordered={true}
                      className="branchbox clickable-card"
                      onClick={() => handleCardClick(item)}
                    >
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={18}>
                            <Title level={4}>
                              {item.branchname} <small className="bnb2"></small>
                            </Title>
                            <h6>
                              <b>{item.debtors.length}</b> Debtors |{" "}
                              <b>
                                {
                                  item.loans.filter(
                                    (loan) => loan.status === "pending"
                                  ).length
                                }
                              </b>{" "}
                              Applications
                            </h6>
                          </Col>
                          <Col xs={6}>
                            <div className="icon-box">
                              <HomeFilled />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Content>
        )}
      </Layout>
    </>
  );
};

export default Branches;

import { Row, Col, Card, Table, Input, Button, Avatar, Typography } from "antd";

import { useHistory } from "react-router-dom";
import React from "react";
import { useState, useContext } from "react";
import { userContext } from "../context/UserContext";

const Clients = () => {
  const { Title } = Typography;

  const history = useHistory();

  const { branch } = useContext(userContext);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleViewRecord = (debtor) => {
    // Navigate to another component and pass the record data
    history.push("/profile", { debtor });
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "32%",
      render: (name, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={record.photo}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record.first_name + " " + record.last_name}
            </Title>
            <p>{record.email}</p>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.phone}</Title>
          </div>
        </>
      ),
    },

    {
      title: "# OF PENDING APPLICATIONS",
      key: "loans",
      dataIndex: "loans",
      render: (loans, record) => (
        <>
          <Button
            type="secondary"
            className="tag-secondary"
            onClick={() => handleViewRecord(record)}
          >
            {loans.filter((loan) => loan.status === "pending").length}
          </Button>
        </>
      ),
    },

    {
      title: "# OF LOANS",
      key: "loans",
      dataIndex: "loans",
      render: (loans, record) => (
        <>
          <Button
            type="primary"
            className="tag-primary"
            onClick={() => handleViewRecord(record)}
          >
            {loans.filter((loan) => loan.status === "approved").length}
          </Button>
        </>
      ),
    },
    {
      title: "OCCUPATION",
      key: "occupation",
      dataIndex: "occupation",
      render: (occupation, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{occupation}</Title>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "view",
      render: (text, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Clients"
              extra={
                <>
                  <Input.Search
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchText}
                  />
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={branch.debtors}
                  className="ant-border-space"
                  pagination={{
                    pageSize: 10,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Clients;

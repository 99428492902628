import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Table,
  Empty,
  Typography,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
import Loader from "../components/loader";

const Overview = ({ client }) => {
  const { Title } = Typography;

  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAlerts = async () => {
    try {
      //setLoading(true);
      const response = await axios.get(
        `https://gpnserver.pickmesms.com/items/messages?filter[phone][_eq]=${client.phone}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      //setLoading(false);

      setAlerts(response.data.data);
    } catch (error) {
      //setLoading(false);
      //message.error(error.message);
    }
  };

  const resetPassword = async () => {
    const min = 1000; // Minimum 4-digit number
    const max = 9999; // Maximum 4-digit number
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    const data = { password: randomNum };

    setLoading(true);

    axios
      .patch(`https://gpnserver.pickmesms.com/users/${client.id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const message = `We have reset your password to ${randomNum}`;

        sendSMS(message);
      })
      .catch((error) => {
        // Handle the error
      });
  };

  useEffect(() => {
    getAlerts();
  });

  const sendSMS = async (msg) => {
    setLoading(false);

    const values = {
      phone: client.phone,
      message: msg,
    };

    try {
      // Make a POST request using Axios
      await axios.post("https://cgrate.pickmesms.com/gpnsms", values);

      // You can perform further actions with the API response here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const humanize = (mydate) => {
    const date = new Date(mydate);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = date.toLocaleString("en-US", options);

    return formattedDate;
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "date_created",
      key: "date_created",
      render: (date) => (
        <>
          <div className="author-info">
            <Title level={5}>{humanize(date)}</Title>
          </div>
        </>
      ),
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      render: (message) => (
        <>
          <div
            className="author-info"
            style={{ whiteSpace: "normal", maxWidth: "300px" }}
          >
            <p>{message}</p>
          </div>
        </>
      ),
    },
  ];

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={8} className="mb-24">
        <Card
          bordered={false}
          title={<h6 className="font-semibold m-0">Profile Information</h6>}
          className="header-solid h-full card-profile-information"
          extra={
            <Button onClick={resetPassword} type="link">
              <EditOutlined />
              Reset Password
            </Button>
          }
          bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
        >
          <hr className="my-10" />
          <Descriptions>
            <Descriptions.Item label="Gender" span={3}>
              {client.gender}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile" span={3}>
              {client.phone}
            </Descriptions.Item>
            <Descriptions.Item label="System Email" span={3}>
              {client.email}
            </Descriptions.Item>

            <Descriptions.Item label="ID" span={3}>
              {client.national_id}
            </Descriptions.Item>
            <Descriptions.Item label="Active Loans" span={3}>
              {client.loans.length}
            </Descriptions.Item>
            <Descriptions.Item label="Occupation" span={3}>
              {client.occupation}
            </Descriptions.Item>
            <Descriptions.Item label="Worlk Status" span={3}>
              {client.workstatus}
            </Descriptions.Item>
            <Descriptions.Item label="Address" span={3}>
              {client.address}
            </Descriptions.Item>
          </Descriptions>
          {loading ? <Loader /> : ""}
        </Card>
      </Col>
      <Col span={24} md={16} className="mb-24 ">
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Inbox"
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={alerts}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 5,
                    }}
                    locale={{
                      emptyText: <Empty description="No Messages" />, // Specify the custom message here
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Overview;

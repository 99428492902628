/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route } from "react-router-dom";

import Signin from "./pages/SignIn";
import React from "react";
import "antd/dist/antd.min.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Master from "./components/master";
import AuthenticatedRoute from "./utils/AuthenticatedRoute";
import Branches from "./pages/branches";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-in" exact component={Signin} />
        <Route path="/branches" exact component={Branches} />
        <AuthenticatedRoute path="/" component={Master} />
      </Switch>
    </div>
  );
}

export default App;

import { Row, Col, Card, Table, Input, Typography, Button } from "antd";
import axios from "axios";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { userContext } from "../context/UserContext";
import {
  DownloadOutlined,
  FileAddFilled,
  MinusCircleFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Loader from "../components/loader";
import { CSVLink } from "react-csv";

const Disbursements = () => {
  const { Title } = Typography;
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const { branch } = useContext(userContext);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const getItems = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://gpnserver.pickmesms.com/items/gpntransactions?filter[branch][_eq]=${branch.id}&fields=loan.debtor_id.first_name,loan.debtor_id.last_name,loan.balance,loan.next_date,type,amount,date_created&filter[type][_eq]=Loan%20Disbursement`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);

      setItems(response.data.data);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const formatdate = (inputDate) => {
    const date = new Date(inputDate);

    // Get day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    const year = date.getFullYear();

    // Use string formatting to pad with leading zeros if needed
    const formattedDate = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;

    return formattedDate;
  };

  const columns = [
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      width: "32%",
      render: (type, record) => (
        <div className="avatar-info">
          <div style={{ display: "flex", alignItems: "center" }}>
            {type === "Loan Repayment" ? (
              <FileAddFilled
                style={{
                  color: "green",
                  fontSize: "20px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <MinusCircleFilled
                style={{ color: "red", fontSize: "20px", marginRight: "20px" }}
              />
            )}

            <div>
              <Title level={5}>{type}</Title>
              <p>{formatdate(record.date_created)}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "LOAN AMOUNT",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => (
        <>
          <div className="author-info">
            <Title level={5}>
              {" "}
              K{parseFloat(record.amount).toLocaleString()}
            </Title>
          </div>
        </>
      ),
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      key: "balance",
      render: (balance, record) => (
        <>
          <div className="author-info">
            <Title level={5}>
              {" "}
              K{parseFloat(record.loan.balance).toLocaleString()}
            </Title>
          </div>
        </>
      ),
    },

    {
      title: "Debtor",
      key: "debtor",
      dataIndex: "debtor",
      render: (debtor, record) => (
        <>
          <div className="author-info">
            <Title level={5}>
              {record.loan.debtor_id.first_name +
                " " +
                record.loan.debtor_id.last_name}
            </Title>
          </div>
        </>
      ),
    },
  ];

  const summary = () => {
    // Calculate and display the total amount at the bottom
    let totalAmount = 0;
    items.forEach((record) => {
      totalAmount += parseFloat(record.amount);
    });

    let totalBalance = 0;
    items.forEach((record) => {
      totalBalance += parseFloat(record.loan.balance);
    });

    let difference = totalBalance - totalAmount;

    return (
      <>
        <h4 style={totalAmountStyle}>
          Total Disbursements: K{totalAmount.toLocaleString()}
        </h4>
        <h4 style={totalBalanceStyle}>
          Total Balance: K{totalBalance.toLocaleString()}
        </h4>
        <h4 style={totalBalanceStyle}>
          Difference: K{difference.toLocaleString()}
        </h4>
      </>
    );
  };

  const totalAmountStyle = {
    marginLeft: "34px",
    marginTop: "34px",
  };

  const totalBalanceStyle = {
    marginLeft: "34px",
    marginTop: "12px",
  };

  const modifiedData = items.map((entry) => ({
    transaction: entry.type,
    debtor: entry.loan
      ? `${entry.loan.debtor_id.first_name} ${entry.loan.debtor_id.last_name}`
      : "",
    amount: entry.amount,
    balance: entry.loan ? entry.loan.balance : "",
    next_payment: entry.loan ? entry.loan.next_date : "",
  }));

  const csvData = [
    { label: "Transaction", key: "transaction" },
    { label: "Debtor", key: "debtor" },
    { label: "Amount", key: "amount" },
    { label: "Balance", key: "balance" },
    { label: "Next Payment", key: "next_payment" },
  ];

  return (
    <>
      <div className="tabled">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Transaction History"
                extra={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CSVLink
                      data={modifiedData}
                      headers={csvData}
                      filename={"transaction_history.csv"}
                      className="ant-btn"
                    >
                      <DownloadOutlined
                        style={{
                          fontSize: "24px",
                          marginTop: "4px",
                          marginLeft: "4px",
                        }}
                      />
                    </CSVLink>
                  </div>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={items}
                    summary={summary}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 5,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Disbursements;

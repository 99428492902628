import React, { useContext, useState } from "react";
import logo from "../assets/images/splash.png";
import { userContext } from "../context/UserContext";
import { ThreeDots } from "react-loader-spinner";

import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";

const Signin = () => {
  const { updateUser } = useContext(userContext);

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    updateUser(values);
  };

  const onFinishFailed = () => {};

  const { Title } = Typography;
  const { Header, Content } = Layout;

  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ backgroundColor: "white" }}
      >
        <Header>
          <div className="header-col header-brand">
            <h3>{process.env.REACT_APP_NAME}</h3>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input type="email" placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="4"
                    color="#1890FF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={loading}
                  />
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={logo} alt="" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Signin;

import { useState } from "react";
import { Row, Col, Card, Avatar, Radio } from "antd";
import BgProfile from "../assets/images/bg-profile.jpg";
import { useLocation } from "react-router-dom";
import Overview from "./overview";
import Contacts from "./contacts";
import Profileloans from "./profileloans";
import React from "react";
import National from "./national";

const Profile = () => {
  const location = useLocation();
  const debtor = location.state.debtor;
  const [selectedTab, setSelectedTab] = useState("a"); // Initial tab

  const handleTabChange = (e) => {
    setSelectedTab(e.target.value);
  };

  const renderTabContent = () => {
    if (selectedTab === "a") {
      return <Overview client={debtor} />;
    } else if (selectedTab === "b") {
      return <Profileloans loanslist={debtor.loans} />;
    } else if (selectedTab === "d") {
      return <National client={debtor} />;
    }
    return null;
  };

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={debtor.photo} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">
                    {`${debtor.first_name} ${debtor.last_name}`}
                  </h4>
                  <p>{debtor.email}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group value={selectedTab} onChange={handleTabChange}>
                <Radio.Button value="a">OVERVIEW</Radio.Button>
                <Radio.Button value="b">LOANS</Radio.Button>
                <Radio.Button value="d">NRC/ID</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
      ></Card>
      {renderTabContent()}
    </>
  );
};

export default Profile;

import { Row, Col, Card, Table, Button, Typography } from "antd";
import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import React from "react";

const Profileloans = ({ loanslist }) => {
  const { Title } = Typography;

  const history = useHistory();

  const handleViewRecord = (loan) => {
    history.push("/loandetails", { loan });
  };

  console.log(loanslist);

  const columns = [
    {
      title: "LOAN AMOUNT",
      dataIndex: "amount",
      key: "amount",
      width: "32%",
      render: (amount, record) => (
        <div className="avatar-info">
          <div style={{ display: "flex", alignItems: "center" }}>
            {record.status == "approved" ? (
              <CheckOutlined
                style={{
                  color: "green",
                  fontSize: "20px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <WarningOutlined
                style={{
                  color: "orange",
                  fontSize: "20px",
                  marginRight: "20px",
                }}
              />
            )}

            <div>
              <Title level={5}>
                K{parseFloat(record.amount).toLocaleString()}
              </Title>
              <p>
                {record.debtor_id.first_name + " " + record.debtor_id.last_name}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      key: "balance",
      render: (balance, record) => (
        <>
          <div className="author-info">
            <Title level={5}>K{balance}</Title>
          </div>
        </>
      ),
    },
    {
      title: "LOAN TYPE",
      dataIndex: "loan_name",
      key: "loan_name",
      render: (loan_name, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.loantype.loan_name}</Title>
          </div>
        </>
      ),
    },

    {
      title: "Interest Rate",
      key: "interest",
      dataIndex: "istallment",
      render: (istallment, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.loantype.interest_rate}%</Title>
          </div>
        </>
      ),
    },
    {
      title: "Collateral",
      key: "collateral",
      dataIndex: "collateral",
      render: (collateral, record) => (
        <>
          <div className="author-info">
            <Title level={5}>
              {record &&
              record.collateral &&
              record.collateral[0] &&
              record.collateral[0].collateral_name
                ? record.collateral[0].collateral_name
                : "Not Provided"}
            </Title>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "debtor_id",
      render: (transactions, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          Details
        </Button>
      ),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Loans"
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={loanslist}
                className="ant-border-space"
                pagination={{
                  pageSize: 5,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profileloans;

import { Row, Col, Card, Table, Input, Empty, Typography, Button } from "antd";

import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Loader from "../components/loader";

const Loans = () => {
  const { Title } = Typography;

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const history = useHistory();
  const handleViewRecord = (loan) => {
    history.push("/loandetails", { loan });
  };

  const [clients, setClients] = useState([]);

  const getClients = async () => {
    try {
      //setLoading(true);
      const response = await axios.get(
        `https://gpnserver.pickmesms.com/items/loans?filter[branch][company][_eq]=3&fields=loantype.loan_name,loantype.interest_rate,loantype.duration,collateral.*,status,amount,id,balance,branch.*,loan_date,debtor_id.*`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);

      setClients(response.data.data);
    } catch (error) {
      setLoading(false);

      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const columns = [
    {
      title: "LOAN AMOUNT",
      dataIndex: "amount",
      key: "amount",
      width: "32%",
      render: (amount, record) => (
        <div className="avatar-info">
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckOutlined
              style={{ color: "green", fontSize: "20px", marginRight: "20px" }}
            />
            <div>
              <Title level={5}>
                K{parseFloat(record.amount).toLocaleString()}
              </Title>
              <p>
                {record.debtor_id.first_name + " " + record.debtor_id.last_name}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      key: "balance",
      render: (balance, record) => (
        <>
          <div className="author-info">
            <Title level={5}>K{balance}</Title>
          </div>
        </>
      ),
    },
    {
      title: "LOAN TYPE",
      dataIndex: "loantype",
      key: "loantype",
      render: (repayable, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.loantype.loan_name}</Title>
          </div>
        </>
      ),
    },

    {
      title: "Interest Rate",
      key: "interest",
      dataIndex: "istallment",
      render: (istallment, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.loantype.interest_rate}%</Title>
          </div>
        </>
      ),
    },
    {
      title: "Collateral",
      key: "collateral",
      dataIndex: "collateral",
      render: (collateral, record) => (
        <>
          <div className="author-info">
            <Title level={5}>
              {record &&
              record.collateral &&
              record.collateral[0] &&
              record.collateral[0].collateral_name
                ? record.collateral[0].collateral_name
                : "Not Provided"}
            </Title>
          </div>
        </>
      ),
    },
    {
      title: "VIEW",
      key: "debtor_id",
      render: (transactions, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          Details
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Approved Loans"
                extra={
                  <>
                    <Input.Search
                      placeholder="Search"
                      onChange={(e) => handleSearch(e.target.value)}
                      value={searchText}
                    />
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={clients.filter(
                      (loan) => loan.status === "approved"
                    )}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 5,
                    }}
                    locale={{
                      emptyText: <Empty description="Session Expired" />,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Loans;
